import {createAsyncThunk} from '@reduxjs/toolkit';
import {getNetworkInfo, getUserProfile} from 'pages/Authorization/AuthorizationUtils';
import {UserNetworkPayload, setNetworkId, setNetworkName, setOwner, setUserId, setActivePlaces} from 'store/userSlice';

export const initializeNetwork = createAsyncThunk<UserNetworkPayload>(
  'user/initializeNetwork',
  async (_, {dispatch}) => {
    // TODO try catch with loading and error state?
    const {network, id: userId, isOwner, places} = await getUserProfile();
    // typeof for skip null value of networkId
    const {id, name} = typeof network === 'string' && (await getNetworkInfo(network));
    dispatch(setNetworkId(id));
    dispatch(setNetworkName(name));
    dispatch(setUserId(userId));
    dispatch(setOwner(isOwner));
    dispatch(setActivePlaces(places.map(({place}: {place: string}) => place)));

    return {id, name};
  }
);
