import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useReducer, ReactNode} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import BaseSeparatorLine from 'components/BaseComponents/BaseSeparatorLine';
import BaseSelector from 'components/BaseComponents/BaseSelector';
import BaseImage from 'components/BaseComponents/BaseImage';
import Header from 'components/GeneralComponents/Header';
import {useAppDispatch, useAppSelector} from 'services/hooks';
import {selectPlacesConfiguration, setPlaceId, setPlaceName} from 'store/placesSlice';
import {selectUserConfiguration} from 'store/userSlice';
import {
  selectNotificationConfiguration,
  setNotifyModalOpen,
  setRouteBlocked,
  setTabChanged,
  setTargetPlaceId,
  setTargetPlaceName,
  setTargetRoute,
} from 'store/notificationSlice';
import {initializeNetwork} from 'store/middleware/userThunk';
import {globalLocalization, initPlacePageLayoutState, PlaceProps, stateReducer} from 'services/GlobalUtils';
import {EstablishmentProps, getPlaces} from 'pages/Places/PlacesUtils';
import {ReactComponent as Main} from 'assets/icons/side_main.svg';
import {ReactComponent as Settings} from 'assets/icons/side_settings.svg';
import {ReactComponent as Configurations} from 'assets/icons/side_configurations.svg';
import {ReactComponent as Department} from 'assets/icons/side_department.svg';
import {ReactComponent as Halls} from 'assets/icons/side_halls.svg';
import {ReactComponent as Personnel} from 'assets/icons/side_personnel.svg';
import {ReactComponent as PaymentType} from 'assets/icons/side_payment.svg';
import {ReactComponent as Reports} from 'assets/icons/side_reports.svg';
import {ReactComponent as Apps} from 'assets/icons/side_apps.svg';
import {ReactComponent as Equip} from 'assets/icons/side_equip.svg';
import {ReactComponent as Menu} from 'assets/icons/side_menu.svg';
import {ReactComponent as Close} from 'assets/icons/close.svg';
import Logo from 'assets/images/logo-text.webp';
import {font_body_large, font_body_small, font_label_large} from 'theme/fonts';

const PlacePageLayout: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {selectedPlaceId} = useAppSelector(selectPlacesConfiguration);
  const {language, networkId, activePlaces} = useAppSelector(selectUserConfiguration);
  const {isRouteBlocked, targetPlaceId} = useAppSelector(selectNotificationConfiguration);
  const [state, stateDispatch] = useReducer(stateReducer, initPlacePageLayoutState);

  useEffect(() => {
    dispatch(initializeNetwork());
  }, [dispatch]);

  const {choosingPlace} = state;

  useEffect(() => {
    const token = localStorage.getItem('BearerToken');
    !token && navigate('/auth');
    token && !selectedPlaceId && navigate('/places');
    token &&
      !choosingPlace &&
      networkId &&
      getPlaces(networkId, 1, 10000, 'asc', language).then(({data: {data}}) => {
        if (data.length === 0) {
          navigate('/places');
        } else {
          stateDispatch({
            placesOptions: data.reduce(
              (acc: {label: string; value: string; img: ReactNode}[], {id, logo, translations}: EstablishmentProps) => {
                if (activePlaces.includes(id)) {
                  acc.push({
                    label: translations[language]?.name,
                    value: id,
                    img: <Image src={logo} />,
                  });
                }
                return acc;
              },
              []
            ),
          });
        }
        stateDispatch({isLoading: false});
      });
    window.scrollTo({top: 0, left: 0});
  }, [activePlaces, choosingPlace, language, navigate, networkId, selectedPlaceId]);

  const {placesOptions} = state;

  const handlePlaceChange = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      const placeName = placesOptions.find((item: PlaceProps) => item.value === value)?.label || '';
      dispatch(setNotifyModalOpen(isRouteBlocked));
      if (isRouteBlocked) {
        dispatch(setTargetRoute('/place-main'));
        dispatch(setTargetPlaceName(placeName));
        dispatch(setTargetPlaceId(value));
      } else {
        dispatch(setNotifyModalOpen(false));
        dispatch(setRouteBlocked(false));
        dispatch(setTabChanged(false));
        dispatch(setPlaceId(value));
        dispatch(setPlaceName(placeName));
        stateDispatch({choosingPlace: value});
      }
    },
    [dispatch, isRouteBlocked, placesOptions]
  );

  useEffect(() => {
    if (!isRouteBlocked && targetPlaceId && targetPlaceId !== choosingPlace) {
      stateDispatch({choosingPlace: targetPlaceId});
      dispatch(setTargetPlaceName(''));
      dispatch(setTargetPlaceId(''));
    }
  }, [choosingPlace, dispatch, isRouteBlocked, targetPlaceId]);

  const {
    ESTABLISHMENT,
    SETTINGS,
    CONFIG,
    DEPARTMENT,
    HALLS,
    PERSONNEL,
    PAYMENT_TYPE,
    REPORTS,
    APPS,
    EQUIP,
    PLACE_MENU,
  } = globalLocalization(language);

  const menuLinks = useMemo(
    () => [
      {
        to: '/place-main',
        text: ESTABLISHMENT,
        icon: <Main />,
      },
      {
        to: '/place-settings',
        text: SETTINGS,
        icon: <Settings />,
      },
      {
        to: '/place-configuration',
        text: CONFIG,
        icon: <Configurations />,
      },
      {
        to: '/place-equipment',
        text: EQUIP,
        icon: <Equip />,
      },
      {
        to: '/place-department',
        text: DEPARTMENT,
        icon: <Department />,
      },
      {
        to: '/place-menu',
        text: PLACE_MENU,
        icon: <Menu />,
      },
      {
        to: '/place-payment',
        text: PAYMENT_TYPE,
        icon: <PaymentType />,
      },
      {
        to: '/place-halls',
        text: HALLS,
        icon: <Halls />,
      },
      {
        to: '/place-personnel',
        text: PERSONNEL,
        icon: <Personnel />,
      },
      {
        to: '/place-apps',
        text: APPS,
        icon: <Apps />,
      },
      {
        to: '/place-reports',
        text: REPORTS,
        icon: <Reports />,
      },
    ],
    [APPS, CONFIG, DEPARTMENT, EQUIP, ESTABLISHMENT, HALLS, PAYMENT_TYPE, PERSONNEL, PLACE_MENU, REPORTS, SETTINGS]
  );

  const {isLoading, isVisible} = state;

  return isLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Header setVisible={() => stateDispatch({isVisible: true})} hasBurger />
      <Layout>
        <MenuSideBar isvisible={isVisible ? 1 : 0}>
          <SidebarHeader>
            <LogoImage src={Logo} />
            <CloseIcon icon={<Close />} onClick={() => stateDispatch({isVisible: false})} />
          </SidebarHeader>
          <PlaceSelectDropdown />
          {placesOptions.length ? (
            <PlaceSelect
              onChange={handlePlaceChange}
              options={placesOptions}
              selectValue={choosingPlace || selectedPlaceId}
            />
          ) : (
            <Loader />
          )}
          <MenuItems>
            {menuLinks.map(({to, text, icon}, idx) => (
              <div key={text}>
                {idx === 10 && <DividerLine horizontal={1} />}
                <MenuItem to={to} isactive={pathname === to ? 1 : 0} onClick={() => stateDispatch({isVisible: false})}>
                  <BaseIcon icon={icon} />
                  {text}
                </MenuItem>
              </div>
            ))}
          </MenuItems>
        </MenuSideBar>
        <SeparatorLine />
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Layout>
      <MenuShadow isvisible={isVisible ? 1 : 0} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${({theme}) => theme.colors.gray100};
  background-color: ${({theme}) => theme.colors.gray010};
  padding-bottom: 2rem;
`;

const Layout = styled.div`
  display: block;
  min-height: calc(100vh - 6.5rem);
  margin: 0;
  border-radius: 1.5rem;
  background-color: ${({theme}) => theme.colors.gray000};

  @media ${({theme}) => theme.breakpoints.minTb} {
    margin: 0 1.5rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: grid;
    grid-template-columns: 15.625rem 1px auto;
  }
`;

const MenuItem = styled(Link)<{isactive: number}>`
  ${font_body_large};
  color: ${({theme}) => theme.colors.gray070};
  border-left: 0.25rem solid transparent;
  text-decoration: none;
  display: grid;
  align-items: center;
  grid-template-columns: 1.7rem 1fr;
  grid-column-gap: 0.75rem;
  height: 3.125rem;
  padding-left: 1rem;

  ${({
    isactive,
    theme: {
      colors: {green060, gray100},
    },
  }) =>
    isactive &&
    `
    border-left-color: ${green060};
    color: ${gray100};
  `}
`;

const MenuSideBar = styled.div<{isvisible: number}>`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 22rem;
  padding: 2rem 0 0;
  transform: translate3d(-${({isvisible}) => (isvisible ? 0 : 100)}%, 0, 0);
  transition: transform 0.3s ease-out;
  background-color: ${({theme}) => theme.colors.gray000};

  & > ${MenuItem} {
    &:nth-last-child(2) {
      margin: 1.6875rem 0 0.625rem;
    }
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    z-index: 0;
    position: initial;
    height: initial;
    width: initial;
    padding: 3rem 0 3.75rem;
    background: transparent;
    transform: none;
  }
`;

const MenuItems = styled.div`
  height: calc(100vh - 12rem);
  overflow-y: auto;
`;

const DividerLine = styled(BaseSeparatorLine)`
  margin: 0.5rem auto;
  width: calc(100% - 2rem);
  border: 1px solid ${({theme}) => theme.colors.gray010};
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1rem 1.5rem;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: none;
  }
`;

const LogoImage = styled(BaseImage)`
  height: 3rem;
`;

const CloseIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    padding: 0.375rem;
    border-radius: 0.75rem;
    border: 1px solid ${({theme}) => theme.colors.gray020};
    fill: ${({theme}) => theme.colors.gray100};
  }
`;

const MenuShadow = styled.div<{isvisible: number}>`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.gray100};
  pointer-events: ${({isvisible}) => (isvisible ? 'initial' : 'none')};
  transform: scale3d(${({isvisible}) => (isvisible ? '1, 1' : '0, 0')}, 1);
  opacity: ${({isvisible}) => (isvisible ? 0.5 : 0)};
  transition:
    opacity 0.3s ease-in,
    transform 0s linear ${({isvisible}) => (isvisible ? 0 : 0.3)}s;
`;

const Container = styled.div`
  max-width: 100%;
  padding: 1.25rem 1rem 3.75rem;
  box-sizing: border-box;
  overflow: hidden;

  @media ${({theme}) => theme.breakpoints.minTb} {
    padding: 2rem 1.5rem 3.75rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    padding: 3rem 1.5rem 3.75rem;
    margin: 0.75rem 0 1.25rem;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    width: clamp(677px, 94%, 1440px);
  }
`;

const SeparatorLine = styled(BaseSeparatorLine)`
  display: none;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: block;
    height: 100%;
    width: 1px;
    background-color: ${({theme}) => theme.colors.gray020};
    border: 0;
  }
`;

const Image = styled(BaseImage)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
`;

const Loader = styled(BaseLoader)`
  display: flex;
  margin: 20% auto;
`;

const PlaceSelect = styled(BaseSelector)`
  &.MuiFormControl-root {
    background-color: ${({theme}) => theme.colors.gray010};
    margin: 0 1.25rem 1.5rem;
    padding: 0.5rem;
    border-radius: 1rem;

    .MuiSelect-select {
      ${font_label_large};
      padding: 0;
    }

    fieldset {
      border: none;
    }
  }
`;

const PlaceSelectDropdown = createGlobalStyle`
  li.MuiMenuItem-root {
    ${font_body_small};
    padding: 0.375rem 0.5rem;
  }
`;

export default PlacePageLayout;
