import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'services/reduxStore';
import {initializeNetwork} from './middleware/userThunk';

export interface UserState {
  language: string;
  languagesContent: string[];
  networkId: string;
  networkName: string;
  name: string;
  activePlaces: string[];
  userId: string;
  isOwner: boolean;
}

export interface UserNetworkPayload {
  id: string;
  name: string;
}

const initialState: UserState = {
  language: 'uk',
  languagesContent: ['uk', 'en'],
  networkId: localStorage.getItem('networkId') || '',
  networkName: localStorage.getItem('networkName') || '',
  name: '',
  activePlaces: [],
  userId: localStorage.getItem('userId') || '',
  isOwner: localStorage.getItem('isOwner') === 'true',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload || 'uk';
    },
    setNetworkId: (state, action: PayloadAction<string>) => {
      state.networkId = action.payload;
      localStorage.setItem('networkId', action.payload);
    },
    setNetworkName: (state, action: PayloadAction<string>) => {
      state.networkName = action.payload;
      localStorage.setItem('networkName', action.payload);
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
      localStorage.setItem('userId', action.payload);
    },
    setOwner: (state, action: PayloadAction<boolean>) => {
      state.isOwner = action.payload;
      localStorage.setItem('isOwner', `${action.payload}`);
    },
    setActivePlaces: (state, action: PayloadAction<string[]>) => {
      state.activePlaces = action.payload;
    },
  },
  extraReducers: (reducer) => {
    reducer.addCase(initializeNetwork.fulfilled, (state: UserState, action: PayloadAction<UserNetworkPayload>) => {
      state.networkId = action.payload.id;
      state.networkName = action.payload.name;
    });
  },
});

export const selectUserConfiguration = (state: RootState) => state.user;

export const {
  actions: {setLanguage, setNetworkId, setNetworkName, setName, setUserId, setOwner, setActivePlaces},
} = userSlice;

export default userSlice.reducer;
